import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  Button,
  Box,
  IconButton,
  useTheme,
  CircularProgress,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
} from "@mui/material";
// import InvestedPropertyColumn from "../../components/investedProperty";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../store";
import {
  // addProperty,
  updateProperty,
} from "../../../features/investedProperties";
import { addScenario, updateScenario, deleteScenario, setCurrentScenario, addProperty } from "../../../features/scenarios";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { tokens } from "../../../theme";
import BookmarkAddedOutlinedIcon from "@mui/icons-material/BookmarkAddedOutlined";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { CurrentPortFolio } from "../../../types/User";
import { Formik, Field } from "formik";
import * as yup from "yup";
import { GetUserProfile } from "../../../hooks/userprofile/user";
import AuthenticatedAPI from "../../../auth_helper";
import { DeleteInvestScenario, GetInvestScenario, SaveInvestScenario } from "../../../hooks/buyerAgent";
import { toast } from "react-toastify";
import InvestedPropertyColumn from "../../components/investedProperty";
import { InvestNetScenario, ScenarioProperty } from "../../../types/BuySell";

const InvestmentPropertiesSchema = yup.object({
  investmentProperties: yup.array().of(
    yup.object({
      deposit: yup
        .number()
        .required("Deposit is required")
        .min(0, "Invalid deposit amount"),
    })
  ),
});

interface BuyAndSellPageProps {
  buyerAgent: any;
}

const BuyAndSellPage: React.FC<BuyAndSellPageProps> = ({ buyerAgent }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loading, setLoading] = useState(true);
  const [scenarioRefresh, setScenarioRefresh] = useState(false);
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [investmentProperties, setInvestmentProperties] = useState<CurrentPortFolio[]>([]);
  console.log('investmentProperties', investmentProperties)
  const propertiesss = useSelector((state: RootState) => state.investedProperties.properties);
  const currentScenario = useSelector((state: RootState) => state.scenarios.currentScenario);
  console.log('currentScenario----<<<<>>><><>', currentScenario)
  const scenarios = useSelector((state: RootState) => state.scenarios.scenarios[0]);
  const scenariosssss = useSelector((state: RootState) => state.scenarios.scenarios);


  console.log('scenarios1212121212', scenariosssss)
  const [scenarioName, setScenarioName] = useState("");
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);
  const [selectedScenarioId, setSelectedScenarioId] = useState<string | null>(null);
  console.log('selectedScenarioId', selectedScenarioId)


  // Filter to get the selected scenario based on the selected ID
  const selectedScenario = Array.isArray(scenarios)
    ? scenarios.find((scenario: any) => scenario.id === selectedScenarioId)
    : null;

  // Extract properties from the selected scenario
  const properties = currentScenario && currentScenario.properties;
  console.log(currentScenario?.properties, '-------------', investmentProperties)
  useEffect(() => {
    const fetchData = async () => {
      const profileData = await GetUserProfile(buyerAgent.investor_id);
      if (profileData && profileData.currentPortfolio) {
        setInvestmentProperties(profileData.currentPortfolio?.investmentProperties);
      }
      setLoading(false);
    };

    fetchData();
  }, [buyerAgent.investor_id]);

  useEffect(() => {
    if (investmentProperties.length > 0 && properties?.length === 0) {
      investmentProperties.forEach((property, index) => {
        dispatch(
          updateProperty({
            index,
            data: {
              id: Number(property.id),
              address: property.address,
              value: property.initialpurchaseprice.toLocaleString(),
              purchaseYear: property.yearofpurchase,
              netYield: 4,
              compoundingAnnualGrowthRate: 7,
              marginalTaxRate: 45,
              loanBeforeSales: 0,
              sellAndPayOffLoans: false,
              leverageRatio: 0,
              deposit: 0,
              equityAccessed: 0,
              modelledYear: 0,
              sellYear: 0,
              ispporproperty: property.ispporproperty
            },
          })
        );
      });
    }
  }, [investmentProperties, dispatch, properties?.length]);


  // useEffect(() => {
  //   // Check if there are no scenarios and investmentProperties are present
  //   if (investmentProperties.length > 0 && (!currentScenario || currentScenario.properties.length === 0)) {
  //     // Initialize a new scenario with default values
  //     const newScenario: InvestNetScenario = {
  //       id: `scenario-${Date.now()}`, // Unique ID
  //       user_id: 'defaultUserId', // Set this to the appropriate user ID or pass it as a prop
  //       scenario_name: 'New Scenario', // Default name for the scenario
  //       modelled_year: 2024, // Default modelled year
  //       passive_income_goal: 10000, // Default passive income goal
  //       effective_tax_rate: 0, // Default effective tax rate
  //       cgt_rate: 0, // Default capital gains tax rate
  //       ppor_loan: 0, // Default PPOR loan amount
  //       properties: [], // Start with an empty properties array
  //       portfolio_analysis: {
  //         totalProjectedValue: 0, // Default total projected value
  //         outstandingLoanAmount: 0, // Default outstanding loan amount
  //         totalPassiveIncome: 0, // Default total passive income
  //         isGoalAchieved: false, // Default to not achieved
  //         pporPayoffProgress: 0, // Default PPOR payoff progress
  //         passiveIncomeProgress: 0, // Default passive income progress
  //       },
  //       sellYear: null, // Default value for sellYear
  //     };

  //     // Set the new scenario as the current one
  //     dispatch(setCurrentScenario(newScenario));

  //     // Add properties based on investmentProperties
  //     investmentProperties.forEach((property) => {
  //       const newProperty: Omit<ScenarioProperty, "id"> = {
  //         address: property.address,
  //         value: property.initialpurchaseprice.toLocaleString(),
  //         purchaseYear: property.yearofpurchase,
  //         netYield: 4,
  //         compoundingAnnualGrowthRate: 7,
  //         marginalTaxRate: 45,
  //         sellAndPayOffLoans: false,
  //         leverageRatio: 0,
  //         deposit: 0,
  //         equityAccessed: 0,
  //         modelledYear: 0,
  //         sellYear: 0,
  //         ispporproperty: property.ispporproperty,
  //         effective_tax_rate: 0, // Include the required property
  //       };

  //       dispatch(addProperty(newProperty));
  //     });
  //   }
  // }, [dispatch, investmentProperties, currentScenario]);










  useEffect(() => {
    dispatch(setCurrentScenario(selectedScenario))
  }, [dispatch, selectedScenario])
  const handleAddProperty = () => {

    dispatch(addProperty({
      address: "",
      value: "",
      purchaseYear: 0,
      deposit: 0,
      leverageRatio: 0,
      compoundingAnnualGrowthRate: 7,
      marginalTaxRate: 45,
      netYield: 4,
      equityAccessed: 0,
      modelledYear: 0,
      ispporproperty: false,
      sellYear: null,
      effective_tax_rate: 0,

    }));

  };


  const handleSave = () => {
    setSaveDialogOpen(true);
  };







  const handleSaveScenario = async () => {

    if (currentScenario) {

      dispatch(updateScenario({
        ...currentScenario,
        scenarioName,
        properties,
      }));
      const response = await SaveInvestScenario({

        scenarioName,
        modelledYear: new Date().getFullYear(),
        passiveIncomeGoal: 0,
        effectiveTaxRate: 0,
        cgtRate: 0,
        pporLoan: 0,
        properties: currentScenario.properties,
        portfolioAnalysis: {
          totalProjectedValue: 0,
          outstandingLoanAmount: 0,
          totalPassiveIncome: 0,
          isGoalAchieved: false,
          pporPayoffProgress: 0,
          passiveIncomeProgress: 0,
        }

      })
      if (response.success) {
        toast.success('Scenario saved successfully')
      }

    } else {

      const response = await SaveInvestScenario({

        scenarioName,
        modelledYear: new Date().getFullYear(),
        passiveIncomeGoal: 0,
        effectiveTaxRate: 0,
        cgtRate: 0,
        pporLoan: 0,
        properties,
        portfolioAnalysis: {
          totalProjectedValue: 0,
          outstandingLoanAmount: 0,
          totalPassiveIncome: 0,
          isGoalAchieved: false,
          pporPayoffProgress: 0,
          passiveIncomeProgress: 0,
        }

      })
      if (response.success) {
        toast.success('Scenario saved successfully')
      }

    }
    setSaveDialogOpen(false);
   await fetchScenarios()
    // window.location.replace('/buy-sell')
    // setScenarioRefresh(!scenarioRefresh)
  };

  const handleLoadScenario = () => {
    const selectedScenario = scenarios.find((s: any) => s.id === selectedScenarioId);
    if (selectedScenario) {
      dispatch(setCurrentScenario(selectedScenario));
      // Update properties state with the loaded scenario's properties
      selectedScenario.properties.forEach((property: any, index: number) => {
        dispatch(updateProperty({ index, data: property }));
      });
    }
  };
  console.log('currentScenario', currentScenario)
  const handleDeleteScenario = async () => {
    if (selectedScenarioId) {
      setLoading(true)
      const deletescenario = await DeleteInvestScenario(selectedScenarioId);
      if (deletescenario.success) {
        toast.success('Scenario deleted successfully');
        dispatch(deleteScenario(selectedScenarioId));
        setSelectedScenarioId(null);
        setLoading(false)
        window.location.replace('/buy-sell')
      } else {
        setLoading(false)
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const transformProperties = useCallback(() => {
    return investmentProperties.map(property => {
      const {
        address,
        initialpurchaseprice: value,
        currentloanamount,
        yearofpurchase: purchaseYear,
        ispporproperty,
        id,
        netrentalyield: netYield
      } = property;

      // Calculate the deposit
      const deposit = property.initialpurchaseprice - currentloanamount;

      // Calculate leverage ratio
      const leverageratio = (Number(property.initialpurchaseprice) * 1.06 - Number(deposit)) / Number(property.initialpurchaseprice) * 100;

      // Return the transformed object
      return {
        address,
        value, // initialpurchaseprice becomes value
        purchaseYear, // yearofpurchase becomes purchaseYear
        deposit, // calculated as initialpurchaseprice - currentloanamount
        ispporproperty, // remains the same
        id, // remains the same
        compoundingAnnualGrowthRate: 7,
        effective_tax_rate: 0,
        equityAccessed: 0,
        leverageRatio: leverageratio,
        marginalTaxRate: 45,
        netYield: netYield, // Convert net rental yield to number
        sellAndPayOffLoans: false,
        sellYear: 0
      };
    });
  }, [investmentProperties]);

  console.log('transformProperties(', transformProperties())



  const fetchScenarios = async () => {

    setLoading(true)
    try {
      const res = await GetInvestScenario();
      if (res?.data[0]?.properties?.length > 0) {

        setSelectedScenarioId(res.data[0]?.id)
        // setScenarioName(res.data[0]?.scenario_name)
        
        dispatch(addScenario(res.data));
        setLoading(false)
      } else {


        setScenarioName('Deafult Scenario')
        const response = await SaveInvestScenario({

          scenarioName,
          modelledYear: new Date().getFullYear(),
          passiveIncomeGoal: 0,
          effectiveTaxRate: 0,
          cgtRate: 0,
          pporLoan: 0,
          properties: transformProperties(),
          portfolioAnalysis: {
            totalProjectedValue: 0,
            outstandingLoanAmount: 0,
            totalPassiveIncome: 0,
            isGoalAchieved: false,
            pporPayoffProgress: 0,
            passiveIncomeProgress: 0,
          }

        })
      }
    } catch (error) {
      setLoading(false)

      console.error("Error fetching scenarios:", error);
    }
  };

  useEffect(() => {

    fetchScenarios();
  }, [dispatch, scenarioName, transformProperties]); // Only run once when the component mounts or when 'dispatch' changes


  const handleNext = async () => {
    if (!buyerAgent && !selectedScenarioId) {
      toast.error('please save scenario first in order to proceed !')
      return
    }
    if (buyerAgent) {
      navigate(`/buy-sell-modelling?agentId=${buyerAgent.investor_id}`);
    } else {
      navigate(`/buy-sell-modelling?scenarioId=${selectedScenarioId}`);
    }
  };

  return (
    <Formik
      initialValues={{ investmentProperties: properties }}
      validationSchema={InvestmentPropertiesSchema}
      onSubmit={(values) => console.log(values)}
    >
      {({ validateForm }) => (
        <Container>
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            textAlign="center"
            marginTop={4}
            sx={{ color: colors.grey[100], py: 2 }}
          >
            Investnet Scenario
          </Typography>

          {/* Scenario Management UI */}
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 4, color: colors.grey[100] }}>
            <Select
              value={selectedScenarioId || ""}
              onChange={(e) => setSelectedScenarioId(e.target.value as string)}
              displayEmpty
              sx={{ minWidth: 200 }}
            >
              <MenuItem value="" disabled>Select a scenario</MenuItem>
              {scenarios?.map((scenario: any) => {
                return (
                  <MenuItem key={scenario.id} value={scenario.id}>{scenario.scenario_name}</MenuItem>

                )
              })}
            </Select>
            <Box>
              {/* <Button
                variant="contained"
                color="primary"
                onClick={handleLoadScenario}
                startIcon={<FolderOpenIcon />}
                sx={{ mr: 2 }}
                disabled={!selectedScenarioId}
              >
                Load Scenario
              </Button> */}
              <Button
                variant="contained"
                color="error"
                onClick={handleDeleteScenario}
                startIcon={<DeleteIcon />}
                disabled={!selectedScenarioId}
              >
                Delete Scenario
              </Button>
            </Box>
          </Box>

          <Grid container spacing={4} justifyContent="center">
            {loading ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
              >
                <CircularProgress />
              </Box>
            ) : (
              <>

                {properties?.map((property: any, index: number) => {

                  return (

                    <InvestedPropertyColumn
                      key={property.id}
                      index={index}
                      scenario={currentScenario ?? selectedScenario}
                    // defaultProperty={investmentProperties}

                    />
                  )
                })}


                {properties && properties?.length < 20 && (
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                    <Box
                      padding={2}
                      boxShadow={3}
                      bgcolor="background.paper"
                      borderRadius={2}
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      height="100%"
                      sx={{ backgroundColor: colors.primary[400] }}
                    >
                      <IconButton onClick={handleAddProperty} size="large">
                        <AddIcon fontSize="large" />
                      </IconButton>
                    </Box>
                  </Grid>
                )}
              </>
            )}
          </Grid>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: 4,
              color: colors.grey[100],
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSave}
              startIcon={<BookmarkAddedOutlinedIcon />}
            >
              Save Scenario
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleNext}
              endIcon={<ArrowForwardIcon />}
            >
              Next
            </Button>
          </Box>

          <Dialog open={saveDialogOpen} onClose={() => setSaveDialogOpen(false)}>
            <DialogTitle>Save Scenario</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="scenarioName"
                label="Scenario Name"
                type="text"
                fullWidth
                variant="standard"
                value={scenarioName}
                onChange={(e) => setScenarioName(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setSaveDialogOpen(false)}>Cancel</Button>
              <Button onClick={handleSaveScenario}>Save</Button>
            </DialogActions>
          </Dialog>
        </Container>
      )}
    </Formik>
  );
};

export default BuyAndSellPage;