import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import {
  BarChartPreTaxPassiveIncome,
  BuySellPortfolio,
  InvestedProperty,
  InvestNetScenario,
  ScenarioProperty,
} from "../../../types/BuySell";
// import ProgressBar from 'react-bootstrap/ProgressBar';
// import 'bootstrap/dist/css/bootstrap.min.css';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  IconButton,
  useTheme,
  Tooltip,
  Card,
  Switch,
  TextField,
  Slider,
  Box,
  styled,
  FormControl,
  SelectChangeEvent,
  MenuItem,
  Container,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  setBarChartPreTaxPassiveIncome,
  setLineChartModelledPropertyValue,
  setPieChartLoansAfterSalesItem,
  updateBuySellPortfolio,
  // updateProperty,
} from "../../../features/investedProperties";
import BookmarkAddedOutlinedIcon from "@mui/icons-material/BookmarkAddedOutlined";
import { tokens } from "../../../theme";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useSearchParams } from 'react-router-dom';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as ChartTooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { GetUserProfile } from "../../../hooks/userprofile/user";
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import { emit } from "process";
import { useLocation } from 'react-router-dom';
import { Property } from "../../../types/User";
import Properties from "../Properties";
import { deleteScenario, setCurrentScenario, updateScenario, updateProperty } from "../../../features/scenarios";
import DeleteIcon from "@mui/icons-material/Delete";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { toast } from "react-toastify";
import { SaveInvestScenario } from "../../../hooks/buyerAgent";
interface State extends SnackbarOrigin {
  open: boolean;
}
interface ChartData {
  id: string;
  color: string;
  data: { x: string; y: number }[];
}
interface PassiveGoal {
  passive_income: number;
  // Add other properties if needed
}
interface ProfileData {
  financialGoal: {
    financialGoal: PassiveGoal[];
  };
  // Add other properties if needed
}
interface ModelledChartData {
  loanValuesBeforeSalesObject: { [key: string]: number };
  loanValuesAfterSalesObject: { [key: string]: number };
  remainingCashFromSalesAfterCGTObject: { [key: string]: number };
  propertiesWithNoLoansObject: { [key: string]: number };
  preTaxPassiveIncomeObject: { [key: string]: number };
  [key: string]: { [key: string]: number }; // Index signature for dynamic access
}

const initialProperties = [
  {
    id: 1,
    address: "23 Wilside Crescent",
    initialValue: 636000,
    initialLoan: 508800,
    passiveIncome: 35681,
    leverageRatio: 80,
    netYield: 4,
    growthRate: 7,
    effectiveTaxRate: 33,
    purchaseYear: 2019,
    projectedValue: 1000,
  },
  {
    id: 2,
    address: "2 Kelburn Road",
    initialValue: 606000,
    initialLoan: 454500,
    passiveIncome: 38924,
    leverageRatio: 75,
    netYield: 4,
    growthRate: 7,
    effectiveTaxRate: 33,
    purchaseYear: 2017,
    projectedValue: 500,
  },
  {
    id: 3,
    address: "27 Selina Street",
    initialValue: 350000,
    initialLoan: 245000,
    passiveIncome: 17151,
    leverageRatio: 70,
    netYield: 4,
    growthRate: 7,
    effectiveTaxRate: 33,
    purchaseYear: 2021,
    projectedValue: 1500,
  },
  {
    id: 4,
    address: "59 Oriestone Street",
    initialValue: 406000,
    initialLoan: 263900,
    passiveIncome: 17377,
    leverageRatio: 65,
    netYield: 4,
    growthRate: 7,
    effectiveTaxRate: 33,
    purchaseYear: 2023,
    projectedValue: 0,
  },
];

const PropertyResultsPage: React.FC = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch: AppDispatch = useDispatch();
  const [snackBarState, setSnackBarState] = useState<SnackbarOrigin & { open: boolean }>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const [selectedScenarioId, setSelectedScenarioId] = useState<string | null>(null);
  // console.log('scidd', selectedScenarioId)
  const location = useLocation();
  // const agentId = searchParams.get('agentId') || '';
  const [searchParams] = useSearchParams();
  const agentId = searchParams.get('agentId') || '';
  const scenarioId = searchParams.get('scenarioId');
  useEffect(() => {
    if (scenarioId) {

      setSelectedScenarioId(scenarioId);
      // handleLoadScenario()
    }
  }, [scenarioId]);



  const { vertical, horizontal, open } = snackBarState;
  const propertieses = useSelector(
    (state: RootState) => state.investedProperties.properties
  );
  const currentScenario = useSelector((state: RootState) => state.scenarios.currentScenario);
  // console.log('currentScenario55555', currentScenario)
  // console.log('currentScenario------------------------>>>>', currentScenario)
  const scenarios = useSelector((state: RootState) => state?.scenarios?.scenarios[0]);
  const selectedScenario = scenarios ? scenarios.find((scenario: any) => scenario.id === selectedScenarioId) : null;
  // console.log('selectedScenario', selectedScenario)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const properties = selectedScenario ? selectedScenario.properties : currentScenario?.properties;
  // console.log('propertie22222s', properties)
  // console.log('properties', properties)
  interface Property {
    id: number;
    sellYear?: number | null;
    effective_tax_rate: number;
    InvestedProperty: any;// Optional, can be undefined or null
    // Add other property fields as needed
  }
  // console.log("properties232323---------------------------.>>>>", propertieses,'------->>>',selectedScenario)

  // console.log('900000000sd0s9d09dsadhjdh29',properties.find((p: Property) => p.id === property.id)?.sellYear?.toString() )
  const [modelledYear, setModelledYear] = useState(2024);
  const [propertyGraph, setPropertyGraph] = useState(initialProperties);
  const [passiveGoal, setPassiveGoal] = useState<PassiveGoal | null>(null);
  const [projectedValues, setProjectedValues] = useState<number[]>([]);
  const [remainingLoanAmount, setRemainingLoanAmount] = useState<number[]>([]);
  // console.log('remainingLoanAmount', remainingLoanAmount)
  const [propertyIDS, setPropertyIDS] = useState<string[]>([]);
  const [GoalAchieved, setGoalAchieved] = useState(false);
  const [propertiess, setPropertiess] = useState<any[]>(properties);
  // console.log('s', propertiess)
  const [modelledSellYear, setModelledSellYear] = useState(0);
  const [scenarioName, setScenarioName] = useState("");
  // console.log('projectedValues :>> ', projectedValues);
  const navigate = useNavigate();
  const [saveDialogOpen, setSaveDialogOpen] = useState(false);


  const PPORPayoff = properties.filter((elem: any) => elem.ispporproperty === true);


  const handlePropertyChange = (id: number, key: keyof Property, value: string | number) => {
    setPropertiess(prevProps =>
      prevProps.map(prop =>
        prop.id === id
          ? { ...prop, [key]: key === 'sellYear' ? (value === "Don't Sell" ? null : Number(value)) : Number(value) }
          : prop
      )
    );
  };

  // const handleLoadScenario = () => {
  //   const selectedScenario = scenarios.find((s: any) => s.id === selectedScenarioId);
  //   if (selectedScenario) {
  //     dispatch(setCurrentScenario(selectedScenario));
  //     // Update properties state with the loaded scenario's properties
  //     selectedScenario.properties.forEach((property: any, index: number) => {
  //       dispatch(updateProperty({ index, property }));
  //     });
  //   }
  // };

  const handleLoadScenario = () => {
    const selectedScenario = scenarios.find((s: any) => s.id === selectedScenarioId);

    if (selectedScenario) {
      dispatch(setCurrentScenario(selectedScenario));

      // Update properties state with the loaded scenario's properties
      selectedScenario.properties.forEach((property: ScenarioProperty, index: number) => {
        // Loop through each field of the property and dispatch updates
        Object.keys(property).forEach((field) => {
          dispatch(
            updateProperty({
              updatedProperty: property, // Pass the full property for context
              index: index,
              field: field as keyof ScenarioProperty, // Specify which field is being updated
              value: property[field as keyof ScenarioProperty] // Update the value from the loaded scenario
            })
          );
        });
      });
    }
  };




  // console.log('currentScenario', currentScenario)
  const handleDeleteScenario = () => {
    if (selectedScenarioId) {
      dispatch(deleteScenario(selectedScenarioId));
      setSelectedScenarioId(null);
    }
  };


  const handleSave = () => {
    setSaveDialogOpen(true);
  };


  const handleSaveScenario = async () => {

    if (currentScenario) {

      dispatch(updateScenario({
        ...currentScenario,
        scenarioName,
        properties,
      }));


      const response = await SaveInvestScenario({

        scenarioName,
        modelledYear: new Date().getFullYear(),
        passiveIncomeGoal: 0,
        effectiveTaxRate: 0,
        cgtRate: 0,
        pporLoan: 0,
        properties: currentScenario.properties,
        portfolioAnalysis: {
          totalProjectedValue: 0,
          outstandingLoanAmount: 0,
          totalPassiveIncome: 0,
          isGoalAchieved: false,
          pporPayoffProgress: 0,
          passiveIncomeProgress: 0,
        }

      })
    } else {

      const response = await SaveInvestScenario({

        scenarioName,
        modelledYear: new Date().getFullYear(),
        passiveIncomeGoal: 0,
        effectiveTaxRate: 0,
        cgtRate: 0,
        pporLoan: 0,
        properties,
        portfolioAnalysis: {
          totalProjectedValue: 0,
          outstandingLoanAmount: 0,
          totalPassiveIncome: 0,
          isGoalAchieved: false,
          pporPayoffProgress: 0,
          passiveIncomeProgress: 0,
        }

      })
      // console.log(response, 'lkllk')
      if (response) {
        alert('jkjk')
        toast.success('Scenario saved successfully')
      }

    }
    setSaveDialogOpen(false);
  };

  useEffect(() => {
    if (remainingLoanAmount[0] === 0) {
      setSnackBarState(prevState => ({ ...prevState, open: true }));
    }
  }, [remainingLoanAmount]);

  const handleSnackBarClose = () => {
    setSnackBarState(prevState => ({ ...prevState, open: false }));
  };
  const handleSnackBarOpen = () => {
    setSnackBarState(prevState => ({ ...prevState, open: true }));
  };

  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      e.preventDefault();
      sessionStorage.setItem("redirectAfterReload", "true");
      e.returnValue = ''; // Display the confirmation dialog
      return '';
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const profileData = await GetUserProfile(agentId);
      // console.log(profileData, '----------------------------------------------->')
      if (profileData && profileData.financialGoal && profileData.financialGoal.financialGoal) {

        setPassiveGoal(profileData.financialGoal.financialGoal[0]);

        // dispatch(updateProperty(profileData.currentPortfolio?.investmentProperties))
      }
    };
    fetchData()
  }, [agentId])

  useEffect(() => {
    const redirectAfterReload = sessionStorage.getItem("redirectAfterReload");
    if (redirectAfterReload === "true") {
      sessionStorage.removeItem("redirectAfterReload");
      navigate("/buy-sell", { replace: true }); // Replace with your desired page
    }
  }, [navigate]);

  const [toggleClass, setToggleClass] = useState<boolean>(false);

  // const handleModelledYearChange = (
  //   event: Event,
  //   value: number | number[],
  //   activeThumb: number
  // ) => {
  //   // setModelledYear(value as number);
  //   setModelledYear(value as number);
  //   properties.map((item:any, index:number) => {

  //     dispatch(updateProperty({ index: index, data: { modelledYear: value as number } }));
  //   })
  // };



  const handleModelledYearChange = (
    event: any,
    value: any,
    activeThumb: number
  ) => {

    // Update the local state with the new modelled year value
    setModelledYear(value as number);

    // Iterate over all properties and update the 'modelledYear' field
    properties.map((item: any, index: number) => {
      dispatch(
        updateProperty({
          index: index,                        // Pass the index of the property
          field: 'modelled_year' as keyof ScenarioProperty, // Specify the field to update
          value: value as number,              // Pass the new value for modelledYear
          updatedProperty: item                // Pass the full property context (if needed for other calculations)
        })
      );
    });
  };


  // const handleModelledSellYearChange = (
  //   id: any,
  //   key: string,
  //   value: number | string,
  //   propertyIndex: any
  // ) => {
  //   // setModelledYear(value as number);
  //   setPropertiess(prevProps =>
  //     prevProps.map(prop =>
  //       prop.id === id
  //         ? { ...prop, [key]: key === 'sellYear' ? (value === "Don't Sell" ? null : Number(value)) : Number(value) }
  //         : prop
  //     ))
  //   setModelledSellYear(value as number);

  //   dispatch(updateProperty({ index: propertyIndex, data: { sellYear: value as number } }));
  //   // })
  // };


  const handleModelledSellYearChange = (
    id: any,
    key: string,
    value: number | string,
    propertyIndex: any
  ) => {
    // Update the local properties state
    setPropertiess(prevProps =>
      prevProps.map(prop =>
        prop.id === id
          ? {
            ...prop,
            [key]: key === 'sellYear'
              ? value === "Don't Sell"
                ? null
                : Number(value)
              : Number(value)
          }
          : prop
      )
    );

    // Update the sellYear state
    setModelledSellYear(value as number);

    // Find the property to dispatch as updatedProperty
    const updatedProperty = properties.find((prop: ScenarioProperty) => prop.id === id);

    if (updatedProperty) {
      // Dispatch the action to update the specific property in the global state
      dispatch(
        updateProperty({
          index: propertyIndex,                        // The property index
          field: 'sellYear' as keyof ScenarioProperty, // The field to update
          value: value === "Don't Sell" ? null : Number(value), // The new value for sellYear
          updatedProperty: updatedProperty            // Pass the full property object
        })
      );
    }

    handleModelledYearChange(null, value, 0)

  };



  const projectValueArray = properties.map((elem: any) => elem);




  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const GoalProgressSection = () => (
    <Box sx={{ marginTop: '20px' }}>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }} >Goal Progress</Typography>

      <Typography variant="body1">PPOR Payoff:</Typography>
      <ProgressBarContainer>
        {/* <ProgressBarFill value={Math.min(pporPayoffProgress, 100)} /> */}
        <ProgressBarFill value={percentageSellAndPayOffLoans} />
        {/* <ProgressBarFill value={90} /> */}
      </ProgressBarContainer>
      <Typography variant="body2" sx={{ marginBottom: '16px' }}>
        {/* {`${Math.round(pporPayoffProgress)}% ($${Math.round(totalProjectedValue - totalCapitalGains).toLocaleString()} / $${assumptions.pporLoan.toLocaleString()})`} */}
        {/* {`${PPORPayoffPercentage()}% ($${sumValuesForAllProperties(properties).toFixed(0)}/ $${sumValuesForPporProperties(properties).toFixed(0)})`} */}
        {`${Math.round(percentageSellAndPayOffLoans)}% ($${Math.round(totalSellAndPayOffLoansValue).toLocaleString()} / $${PPORLoan.toLocaleString()})`}

      </Typography>

      <Typography variant="body1">Passive Income:</Typography>
      <ProgressBarContainer>
        <ProgressBarFill
          value={(passiveIncome / Number(passiveGoal?.passive_income)) * 100}
          style={{
            backgroundColor:
              passiveincomeColor <= 40
                ? '#B22222'
                : passiveincomeColor > 40 && passiveincomeColor <= 60
                  ? '#FFD700'
                  : '#006400	', // Setting dynamic color
          }}
        />

      </ProgressBarContainer>
      <Typography variant="body2" sx={{ marginBottom: '16px' }}>
        {`${Math.round((passiveIncome / Number(passiveGoal?.passive_income)) * 100)}%  ($${passiveIncome.toLocaleString()} / $${(passiveGoal && Number(passiveGoal.passive_income)?.toLocaleString()) || ''})`}
      </Typography>


      {/* 
      <Typography variant="body1">Combined Goal:</Typography>
      <ProgressBarContainer>
        <ProgressBarFill value={(passiveGoal && Number(passiveGoal.passive_income)?.toLocaleString()) || ''} /> */}
      {/* <ProgressBarFill value={Math.round((passiveIncome/Number(passiveGoal?.passive_income))*100)} /> */}
      {/* </ProgressBarContainer>
      <Typography variant="body2">
        {`${Math.round((passiveIncome / Number(passiveGoal?.passive_income)) * 100)}% ($${(passiveGoal && Number(passiveGoal.passive_income)?.toLocaleString()) || ''})`}
      </Typography> */}
    </Box>
  );
  const calculateProjectedValue = (value: number, growthRate: number, years: number): number => {
    return value * Math.pow(1 + growthRate / 100, years);
  };
  const calculatePassiveIncome = (projectedValue: number, netYield: number): number => {
    return projectedValue * (netYield / 100);
  };


  // const handleSellAndPayOffLoansChange = (
  //   value: any,
  //   propertyId: number
  // ) => {

  //   const newValue = !isNaN(Number(value));
  //   console.log(newValue, '----------------------------------------------->');
  //   ;
  //   
  //   setPropertyIDS((prev) => {
  //     if (newValue) {
  //       // If the checkbox is checked, add the propertyId if not present
  //       return prev.includes(propertyId.toString())
  //         ? prev
  //         : [...prev, propertyId.toString()];
  //     } else {
  //       // If the checkbox is unchecked, remove the propertyId if present

  //       // Return the filtered property IDs (removing the current propertyId)
  //       return prev.filter((id) => id !== propertyId.toString());
  //     }
  //   });

  //   setToggleClass(true); // Set to true when the switch is toggled

  //   // Update property state in Redux
  //   const updatedProperty: Partial<InvestedProperty> = {
  //     sellAndPayOffLoans: newValue,
  //   };

  //   const propertyIndex = properties.findIndex(
  //     (property:any) => property.id === propertyId
  //   );

  //   if (propertyIndex !== -1) {
  //     dispatch(
  //       updateProperty({
  //         index: propertyIndex,
  //         data: { ...updatedProperty, modelledYear: modelledYear },
  //       })
  //     );
  //     if (!newValue) {
  //       const filteredProperties = properties.filter(
  //         (property:any) => property.id === propertyId
  //       );
  //       ;

  //       const projectedAmountOfProperty = calculateModelledYearPropertyValue(filteredProperties[0]);
  //       ;
  //       const loanAmount = Number(outstandingLoansInProjectionYear.replace(/,/g, ''));
  //       // Sequentially update remainingLoanAmount first, then update propertyIDS
  //       setRemainingLoanAmount((prevRemainingLoanAmount) => {

  //         if (prevRemainingLoanAmount[0] === 0) {

  //           if (testResult === "Goal Achieved") {
  //             const updatedRemainingLoan = loanAmount;
  //             return [updatedRemainingLoan];
  //           } else {
  //             const updatedRemainingLoan = loanAmount - projectedAmountOfProperty;
  //             return [updatedRemainingLoan];
  //           }
  //         } else {

  //           const updatedRemainingLoan = prevRemainingLoanAmount[0] + projectedAmountOfProperty; // Subtract since property is unchecked
  //           return [updatedRemainingLoan];
  //         }
  //       });





  //     } else {
  //       const filteredProperties = properties.filter(
  //         (property:any) => property.id === propertyId
  //       );
  //       ;

  //       const projectedAmountOfProperty = calculateModelledYearPropertyValue(filteredProperties[0]);
  //       ;

  //       // Sequentially update remainingLoanAmount first, then update propertyIDS
  //       setRemainingLoanAmount((prevRemainingLoanAmount) => {

  //         const updatedRemainingLoan = prevRemainingLoanAmount[0] - projectedAmountOfProperty; // Subtract since property is unchecked

  //         return [updatedRemainingLoan > 0 ? updatedRemainingLoan : 0];
  //       });
  //     }
  //   }
  // };

  // useEffect(() => {
  //   const updatedProperties = properties.map(prop => {
  //     const years = modelledYear - prop.purchaseYear;
  //     const projectedValue = calculateProjectedValue(parseInt(prop.value), prop.compoundingAnnualGrowthRate, years);
  //     const passiveIncome = prop.sellYear && prop.sellYear <= modelledYear ? 0 : calculatePassiveIncome(projectedValue, prop.netYield);
  //     const cgt = prop.sellYear && prop.sellYear <= modelledYear ? calculateCapitalGainsTax(parseInt(prop.value), projectedValue, prop.effectiveTaxRate) : 0;
  //     return { ...prop, projectedValue, passiveIncome, cgt };
  //   });
  //   setPropertiess(updatedProperties);
  // }, [modelledYear, properties]);






  //   const totalPassiveIncome = properties.reduce((sum:any, prop:any) => sum + prop.passiveIncome, 0);
  //   const totalProjectedValue = properties.reduce((sum, prop:any) => sum + prop.projectedValue, 0);
  //   const totalCapitalGains = properties.reduce((sum, prop:any) => sum + (prop.cgt || 0), 0);
  //   const sumValuesForPporPropertiess = (properties: InvestedProperty[],DivideWIthIsPPor=false) => {
  //     // Sum the values for all properties (both true and false for isProperty)
  //     const PPORPayoff = properties.filter((elem: any) => elem.ispporproperty === true);
  //     const totalSum = PPORPayoff.reduce((acc, prop) => {
  //       acc += calculateLoansBeforeSales(prop);
  //       return acc;
  //     }, 0);

  //     // Filter properties where isProperty is true
  //     const PporProperty = properties.filter(prop => prop?.ispporproperty);

  //     // Divide by the number of properties where isProperty is true
  //     const divisor = PporProperty.length > 0 ? PporProperty.length : 1;
  //     let result
  //     if(DivideWIthIsPPor){
  // result = totalSum
  //     }else{
  //       result = totalSum / divisor;
  //     }


  //     return result;
  //   };
  //   const PPORLoan :any = sumValuesForPporPropertiess(properties,true).toFixed(0);
  //   const passiveIncomeProgress = (totalPassiveIncome /16048) * 100;
  //   const pporPayoffProgress = ((totalProjectedValue - totalCapitalGains) / PPORLoan) * 100;
  //   const combinedProgress = (passiveIncomeProgress + pporPayoffProgress) / 2;



  // const handleSellAndPayOffLoansChange = (
  //   event: React.ChangeEvent<HTMLInputElement>,
  //   propertyId: number
  // ) => {


  //   const newValue = event.target.checked;
  //   console.log(newValue, '----------------------------------------------->')
  //   
  //   setPropertyIDS((prev) => {
  //     if (newValue) {
  //       // If the checkbox is checked, add the propertyId if not present
  //       return prev.includes(propertyId.toString())
  //         ? prev
  //         : [...prev, propertyId.toString()];
  //     } else {
  //       // If the checkbox is unchecked, remove the propertyId if present
  //       const filteredProperties = properties.filter((property) => property.id === propertyId);
  //       
  //       const loanAmountOfProperty = calculateLoansBeforeSales(filteredProperties[0])
  //       


  //       // const totalProjectedValue = projectedValues.reduce((accumulator, currentValue) => {
  //       //   return accumulator + currentValue;
  //       // }, 0);

  //       // const loanAmount = Number(outstandingLoansInProjectionYear.replace(/,/g, ''));
  //       // 
  //       // const remainingLoan = loanAmount - Number(totalProjectedValue);
  //       setRemainingLoanAmount((prevRemainingLoanAmount) => {
  //         const updatedRemainingLoan = prevRemainingLoanAmount[0] + loanAmountOfProperty;
  //         return [updatedRemainingLoan];
  //       });
  //       
  //       return prev.filter((id) => id !== propertyId.toString());
  //     }
  //   });
  //   setToggleClass(true); // Set to true when switch is toggled
  //   // 
  //   const updatedProperty: Partial<InvestedProperty> = {
  //     sellAndPayOffLoans: newValue,
  //   };

  //   // outstandingLoansInProjectionYear
  //   const propertyIndex = properties.findIndex(
  //     (property) => property.id === propertyId
  //   );
  //   // 
  //   if (propertyIndex !== -1) {

  //     dispatch(
  //       updateProperty({
  //         index: propertyIndex,
  //         data: { ...updatedProperty, modelledYear: modelledYear },
  //       })
  //     );
  //   }
  // };





  const handleSellAndPayOffLoansChange = (value: any, propertyId: number) => {
    const newValue = !isNaN(Number(value));
    console.log(newValue, '----------------------------------------------->');

    // Update property IDs state based on checkbox state
    setPropertyIDS((prev) => {
      if (newValue) {
        // If the checkbox is checked, add the propertyId if not present
        return prev.includes(propertyId.toString())
          ? prev
          : [...prev, propertyId.toString()];
      } else {
        // If the checkbox is unchecked, remove the propertyId if present
        return prev.filter((id) => id !== propertyId.toString());
      }
    });

    setToggleClass(true); // Set to true when the switch is toggled

    // Update property state in Redux
    const propertyIndex = properties.findIndex(
      (property: any) => property.id === propertyId
    );

    if (propertyIndex !== -1) {
      // Retrieve the current property object
      const currentProperty = properties[propertyIndex];

      // Dispatch the updateProperty action
      dispatch(
        updateProperty({
          index: propertyIndex,
          field: 'sellAndPayOffLoans' as keyof ScenarioProperty, // Ensure field is of ScenarioProperty type
          value: newValue, // New value for sellAndPayOffLoans
          updatedProperty: currentProperty // Pass the full property object
        })
      );

      const projectedAmountOfProperty = calculateModelledYearPropertyValue(currentProperty);
      const loanAmount = Number(outstandingLoansInProjectionYear.replace(/,/g, ''));

      // Update remainingLoanAmount based on the newValue
      setRemainingLoanAmount((prevRemainingLoanAmount) => {
        if (newValue) {
          // If the checkbox is checked, subtract the projected amount
          const updatedRemainingLoan = prevRemainingLoanAmount[0] - projectedAmountOfProperty;
          return [updatedRemainingLoan > 0 ? updatedRemainingLoan : 0];
        } else {
          // If the checkbox is unchecked, add the projected amount back
          return prevRemainingLoanAmount[0] === 0 ? [loanAmount] : [prevRemainingLoanAmount[0] + projectedAmountOfProperty];
        }
      });
    }
  };





  const handleCompoundingAnnualGrowthRateChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    propertyId: number
  ) => {
    const newCompoundingAnnualGrowthRate = parseFloat(event.target.value);

    const propertyIndex = properties.findIndex(
      (property: InvestedProperty) => property.id === propertyId
    );

    if (propertyIndex !== -1) {
      const currentProperty = properties[propertyIndex];

      // Dispatch the updateProperty action with the updated field
      dispatch(
        updateProperty({
          index: propertyIndex,
          field: 'compoundingAnnualGrowthRate' as keyof ScenarioProperty, // Ensure this field is a key of ScenarioProperty
          value: newCompoundingAnnualGrowthRate, // The new compounding annual growth rate value
          updatedProperty: { ...currentProperty, compoundingAnnualGrowthRate: newCompoundingAnnualGrowthRate } // Update the full property object
        })
      );
    }
  };

  // console.log('projectedValues :>> ', projectedValues);

  const handleMarginalTaxRateChange = (event: React.ChangeEvent<HTMLInputElement>) => {

    const newTaxRate = parseFloat(event.target.value);

    properties.forEach((property: InvestedProperty, index: number) => {
      const updatedProperty: Partial<InvestedProperty> = {
        marginalTaxRate: newTaxRate,
      };
      // dispatch(updateProperty({ index, data: updatedProperty }));
    });
  };

  // const handleNetYieldChange = (
  //   event: React.ChangeEvent<HTMLInputElement>,
  //   propertyId: number
  // ) => {
  //   const updatedProperty: Partial<InvestedProperty> = {
  //     netYield: parseFloat(event.target.value),
  //   };
  //   const propertyIndex = properties.findIndex(
  //     (property:InvestedProperty) => property.id === propertyId
  //   );
  //   if (propertyIndex !== -1) {
  //     dispatch(updateProperty({ index: propertyIndex, data: updatedProperty }));
  //   }
  // };


  const handleNetYieldChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    propertyId: number
  ) => {
    const newNetYield = parseFloat(event.target.value); // Parse the new net yield from the input

    const propertyIndex = properties.findIndex(
      (property: InvestedProperty) => property.id === propertyId
    );

    if (propertyIndex !== -1) {
      const currentProperty = properties[propertyIndex];

      // Dispatch the updateProperty action with the correct structure
      dispatch(
        updateProperty({
          index: propertyIndex, // Specify the index of the property to update
          field: 'netYield' as keyof ScenarioProperty, // Ensure this field is a key of ScenarioProperty
          value: newNetYield, // The new net yield value
          updatedProperty: { ...currentProperty, netYield: newNetYield } // Update the full property object
        })
      );
    }
  };


  // const handleEffectiveTaxRateChange = (
  //   event: React.ChangeEvent<HTMLInputElement>,
  //   propertyId: number
  // ) => {

  //   const updatedProperty: Partial<InvestedProperty> = {
  //     effectiveTaxRate: Number(event),
  //   };
  //   const propertyIndex = properties.findIndex(
  //     (property:InvestedProperty) => property.id === propertyId
  //   );
  //   if (propertyIndex !== -1) {
  //     dispatch(updateProperty({ index: propertyIndex, data: updatedProperty }));
  //   }
  // };

  // eslint-disable-next-line react-hooks/exhaustive-deps


  const handleEffectiveTaxRateChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    propertyId: number
  ) => {
    const newEffectiveTaxRate = Number(event.target.value); // Correctly parse the input value

    const propertyIndex = properties.findIndex(
      (property: InvestedProperty) => property.id === propertyId
    );

    if (propertyIndex !== -1) {
      const currentProperty = properties[propertyIndex];

      // Dispatch the updateProperty action with the correct structure
      dispatch(
        updateProperty({
          index: propertyIndex, // Specify the index of the property to update
          field: 'effectiveTaxRate' as keyof ScenarioProperty, // Ensure this field is a key of ScenarioProperty
          value: newEffectiveTaxRate, // The new effective tax rate value
          updatedProperty: { ...currentProperty, effectiveTaxRate: newEffectiveTaxRate } // Spread current property and add the new value
        })
      );
    }
  };


  // eslint-disable-next-line react-hooks/exhaustive-deps
  const calculateModelledYearPropertyValue = (property: InvestedProperty) => {

    // const yearsDifference = Number(properties[0]?.modelledYear === 0 ? modelledYear : properties[0]?.modelledYear) - Number(property.purchaseYear);
    const yearsDifference = Number(modelledYear - Number(property.purchaseYear))

    const modelledYearPropertyValue =
      parseFloat(property.value) *
      Math.pow(1 + property.compoundingAnnualGrowthRate / 100, yearsDifference);
    return Math.round(modelledYearPropertyValue);
  };
  ///'Modelled year' loans before sales nee to add + quity access

  const calculateModelledYearLeverageRatio = (property: InvestedProperty) => {
    const loansBeforeSales = calculateLoansBeforeSales(property);
    const propertyValue = calculateModelledYearPropertyValue(property);

    if (propertyValue === 0) {
      return 0;
    } else {
      return (loansBeforeSales / propertyValue) * 100;
    }
  };
  const calculateLoansBeforeSales = (property: InvestedProperty) => {

    return (property.leverageRatio / 100) * parseFloat(property.value);
  };
  const calculateLoansAfterSales = (property: InvestedProperty) => {
    return property.sellAndPayOffLoans
      ? 0
      : calculateLoansBeforeSales(property);
  };
  // console.log('passiveGoal :>> ', passiveGoal);



  const sumValuesForPporProperties = (properties: InvestedProperty[], DivideWIthIsPPor = false) => {
    // Sum the values for all properties (both true and false for isProperty)
    const PPORPayoff = properties.filter((elem: any) => elem.ispporproperty === true);
    const totalSum = PPORPayoff.reduce((acc, prop) => {
      acc += calculateLoansBeforeSales(prop);
      return acc;
    }, 0);

    // Filter properties where isProperty is true
    const PporProperty = properties.filter(prop => prop?.ispporproperty);

    // Divide by the number of properties where isProperty is true
    const divisor = PporProperty.length > 0 ? PporProperty.length : 1;
    let result
    if (DivideWIthIsPPor) {
      result = totalSum
    } else {
      result = totalSum / divisor;
    }


    return result;
  };
  const sumValuesForAllProperties = (properties: InvestedProperty[], DivideWIthIsPPor = false) => {
    // Sum the values for all properties (both true and false for isProperty)
    const totalSum = properties.reduce((acc, prop) => {
      acc += calculateLoansBeforeSales(prop);
      return acc;
    }, 0);
    return totalSum;
  };

  // console.log('propertyIDS :>> ', propertyIDS);

  const ProgressBarContainer = styled(Box)({
    width: '100%',
    height: '24px',
    backgroundColor: '#e0e0e0',
    borderRadius: '12px',
    overflow: 'hidden',
    marginBottom: '8px',
  });
  const ProgressBarFill = styled(Box)<{ value: any }>(({ value }) => ({
    width: `${Math.min(value, 100)}%`, // Ensure value doesn't exceed 100%
    height: '100%',
    backgroundColor: '#4caf50',
    transition: 'width 0.5s ease-in-out',
  }));
  const PPORPayoffPercentage = () => {
    const pporValue = sumValuesForPporProperties(properties);
    const totalValue = sumValuesForAllProperties(properties);

    if (totalValue === 0) return 0; // Prevent division by zero

    // Round only the percentage result
    return Math.round((pporValue / totalValue) * 100);
  };



  const calculateCapitalGainsTax = (value: number, projectedValue: number, effectiveTaxRate: number): number => {
    const gain = projectedValue - value;
    return gain * (effectiveTaxRate / 100);
  };



  const calculateProjectedPropertyValue = (property: InvestedProperty) => {
    const yearsDifference =
      Number(properties[0]?.modelledYear === 0 ? modelledYear : properties[0]?.modelledYear) -
      Number(property.purchaseYear);

    const modelledYearPropertyValue =
      parseFloat(property.value) * Math.pow(1 + property.compoundingAnnualGrowthRate / 100, yearsDifference);

    return Math.round(modelledYearPropertyValue);
  };

  const newcalculateProjectedPropertyValue = (property: InvestedProperty) => {
    const yearsDifference =
      Number(property.modelledYear === 0 ? modelledYear : property.modelledYear) -
      Number(property.purchaseYear);

    const modelledYearPropertyValue =
      parseFloat(property.value) * Math.pow(1 + property.compoundingAnnualGrowthRate / 100, yearsDifference);

    return Math.round(modelledYearPropertyValue);
  };



  // Calculate total projected value by summing up the values for all properties
  // const totalProjectedValue = projectValueArray
  //   .filter(property => property.sellAndPayOffLoans === true)  // Filter properties with sellAndPayOffLoans as true
  //   .map(calculateProjectedPropertyValue)  // Calculate value for each property
  //   .reduce((acc, value) => acc + value, 0);  // Sum all values

  const PPORLoan: any = sumValuesForPporProperties(properties, true);
  const effectiveTaskRate = properties[0]?.marginalTaxRate
  const firstPropertyValue = properties[0]?.value;
  // const cgt = prop.sellYear && prop.sellYear <= modelledYear ? calculateCapitalGainsTax(prop.initialValue, projectedValue, prop.effectiveTaxRate) : 0;
  //       return { ...prop, projectedValue, passiveIncome, cgt };
  // const totalCapitalGains = calculateCapitalGainsTax(parseInt(firstPropertyValue),totalProjectedValue,effectiveTaskRate)
  const totalCapitalGains = properties.reduce((acc: number, prop: InvestedProperty) => {
    const projectedValue = calculateProjectedValue(parseInt(prop?.value), prop?.compoundingAnnualGrowthRate, modelledYear - prop?.purchaseYear);
    const capitalGains = prop.sellYear && prop?.sellYear <= modelledYear
      ? calculateCapitalGainsTax(parseInt(prop?.value), projectedValue, prop?.marginalTaxRate)
      : 0;
    return acc + capitalGains;
  }, 0);
  // const pporPayoffProgress = ((totalProjectedValue - totalCapitalGains) / PPORLoan) * 100;
  const totalProjectedValue = projectValueArray
    .map(calculateProjectedPropertyValue)
    .reduce((acc: any, value: any) => acc + value, 0);

  // Total projected value of properties where sellAndPayOffLoans is true
  const totalSellAndPayOffLoansValue = projectValueArray
    .filter((property: InvestedProperty) => property.sellAndPayOffLoans === true)
    .map((property: InvestedProperty) => calculateProjectedPropertyValue(property))
    .reduce((acc: number, value: number) => acc + value, 0);

  const percentageSellAndPayOffLoans = (totalSellAndPayOffLoansValue / PPORLoan) * 100;


  const calculateRemainingCashFromSales = (property: InvestedProperty) => {
    const loansAfterSales = calculateLoansAfterSales(property);
    // 
    if (loansAfterSales === 0) {
      const modelledYearPropertyValue =
        calculateModelledYearPropertyValue(property);
      const loansBeforeSales = calculateLoansBeforeSales(property);

      const capitalGain =
        (modelledYearPropertyValue - parseFloat(property.value)) * 0.5; // Assuming 50% capital gain
      const capitalGainsTax = capitalGain * (property.marginalTaxRate / 100);

      return modelledYearPropertyValue - loansBeforeSales - capitalGainsTax;
    } else {
      return 0;
    }
  };

  const calculatePropertyValueWithNoLoans = (
    property: InvestedProperty,
    totalPreTaxPassiveIncome: number
  ) => {
    const loansAfterSales = properties.reduce(
      (total: any, property: InvestedProperty) => total + calculateLoansAfterSales(property),
      0
    );

    const remainingCashafterFromSales = properties.reduce(
      (total: any, property: InvestedProperty) => total + calculateRemainingCashFromSales(property),
      0
    );

    if (totalPreTaxPassiveIncome < remainingCashafterFromSales) {
      return property.sellAndPayOffLoans
        ? 0
        : calculateModelledYearPropertyValue(property);
    } else {
      return "failed";
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const calculatePreTaxPassiveIncome = ((
    property: InvestedProperty,
    totalPreTaxPassiveIncome: number
  ) => {

    const propertyValue = calculatePropertyValueWithNoLoans(
      property,
      totalPreTaxPassiveIncome
    );
    if (propertyValue === "failed") {
      return 0;
    } else {
      return Math.round(
        Number(propertyValue) * (Number(property.netYield) / 100)
      );
    }
  });

  const isTestPassed = (): "Goal Achieved" | "Goal Not Achieved" => {
    if (!properties || properties.length === 0) {
      console.error("Properties array is empty or undefined.");
      return "Goal Not Achieved";
    }


    return GoalAchieved && GoalAchieved
      ? "Goal Achieved"
      : "Goal Not Achieved";
  };







  //   useEffect(()=>{

  //     const filteredProperties = properties.filter((property) =>
  //       propertyIDS.includes(property.id.toString())
  //     );

  //     // Calculate projected values for the filtered properties
  //     const newProjectedValues = filteredProperties.map((property) =>
  //       calculateModelledYearPropertyValue(property)
  //     );

  //     setProjectedValues((prev) => {
  //       // Identify property IDs to remove
  //       const propertyIdsToRemove = prev
  //         .map((value, index) => propertyIDS[index])
  //         .filter((id) => !filteredProperties.some((property) => property.id.toString() === id));

  //       // Create a new array without the values corresponding to the IDs to be removed
  //       const updatedProjectedValues = prev.filter((value, index) =>
  //         !propertyIdsToRemove.includes(propertyIDS[index])
  //       );

  //       // Combine the remaining projected values with the new ones, avoiding duplicates
  //       newProjectedValues.forEach((value) => {
  //         if (!updatedProjectedValues.includes(value)) {
  //           updatedProjectedValues.push(value);
  //         }
  //       });

  //       return updatedProjectedValues;
  //     });
  //     // setProjectedValues((prev) => [
  //     //   ...prev,
  //     //   Number(projectedValues),
  //     // ]);

  //     const totalProjectedValue = projectedValues.reduce((accumulator, currentValue) => {
  //       return accumulator + currentValue;
  //     }, 0);
  //     const res = Number(totalProjectedValue) >= Number(outstandingLoansInProjectionYear.replace(/,/g, ''))
  //    const passiveIncome =  properties.reduce(
  //       (total, property) => total + calculatePreTaxPassiveIncome(property, totalPreTaxPassiveIncome),
  //       0
  //     )
  //     const passiveTotal = Number(passiveIncome) >= Number(passiveGoal?.passive_income)

  //     const IsGoalacheived = res && passiveTotal 
  // console.log('IsGoalacheived :>> ', IsGoalacheived);
  // setGoalAchieved(IsGoalacheived)
  //   },[propertyIDS,modelledYear])
  // useEffect(() => {

  //   if (propertyIDS.length === 0) {
  //     const initialPropertyIds = properties
  //       .filter((property) => property.sellAndPayOffLoans)
  //       .map((property) => property.id.toString());

  //     setPropertyIDS(initialPropertyIds);

  //     return;
  //     // const totalProjectedValue = projectedValues.reduce((accumulator, currentValue) => {
  //     //   return accumulator + currentValue;
  //     // }, 0);
  //     // 
  //   }
  //   const filteredProperties = properties.filter((property) =>
  //     propertyIDS.includes(property.id.toString())
  //   );

  //   // Calculate projected values for the filtered properties
  //   const newProjectedValues = filteredProperties.map((property) => ({
  //     id: property.id.toString(),
  //     value: calculateModelledYearPropertyValue(property)
  //   }));

  //   setProjectedValues((prev) => {
  //     // Create a map of current projected values for quick lookup
  //     const projectedValueMap = new Map(prev.map((value, index) => [propertyIDS[index], value]));

  //     // Update the map with the new projected values
  //     newProjectedValues.forEach(({ id, value }) => {
  //       projectedValueMap.set(id, value);
  //     });

  //     // Convert the map back to an array, maintaining the order of propertyIDS
  //     const updatedProjectedValues = propertyIDS.map((id) => projectedValueMap.get(id)).filter((value): value is number => value !== undefined);

  //     return updatedProjectedValues;
  //   });

  //   // Calculate total projected value
  //   const totalProjectedValue = projectedValues.reduce((accumulator, currentValue) => {
  //     return accumulator + currentValue;
  //   }, 0);

  //   // Check if the total projected value meets or exceeds the outstanding loans amount
  //   const res = Number(totalProjectedValue) >= Number(outstandingLoansInProjectionYear.replace(/,/g, ''));

  //   // Calculate passive income
  //   const passiveIncome = properties.reduce(
  //     (total, property) => total + calculatePreTaxPassiveIncome(property, totalPreTaxPassiveIncome),
  //     0
  //   );

  //   // Check if the passive income meets or exceeds the passive income goal
  //   const passiveTotal = Number(passiveIncome) >= Number(passiveGoal?.passive_income);

  //   // Determine if the goal is achieved
  //   const isGoalAchieved = res && passiveTotal;
  //   console.log('IsGoalAchieved :>> ', isGoalAchieved);
  //   setGoalAchieved(isGoalAchieved);
  // }, [propertyIDS, modelledYear]);


  const cashResidue = properties
    .reduce(
      (total: any, property: InvestedProperty) => total + calculateRemainingCashFromSales(property),
      0
    )
    .toLocaleString();
  const handleBack = () => {
    navigate("/buy-sell");
  };



  const years = Array.from({ length: 21 }, (_, index) => 2020 + index);
  // pre-tax passive income
  const totalPreTaxPassiveIncome = properties.reduce(
    (total: any, property: InvestedProperty) =>
      total +
      Math.round(
        Number(calculatePropertyValueWithNoLoans(property, 0)) *
        (Number(property.netYield) / 100)
      ),
    0
  );
  const passiveIncome = properties.reduce(
    (total: any, property: InvestedProperty) => total + calculatePreTaxPassiveIncome(property, totalPreTaxPassiveIncome),
    0
  );
  const passiveincomeColor = (passiveIncome / Number(passiveGoal?.passive_income)) * 100

  // modelled year property value
  const modelledPropertyValues = properties
    .reduce(
      (total: any, property: InvestedProperty) => total + calculateModelledYearPropertyValue(property),
      0
    )
    .toLocaleString();

  //'Modelled year' pre-tax passive income
  const calculatedValues = properties
    .reduce(
      (total: any, property: InvestedProperty) =>
        total +
        calculatePreTaxPassiveIncome(property, totalPreTaxPassiveIncome),
      0
    )
    .toLocaleString();

  // inital property value
  const totalPropertyValue = properties.reduce(
    (total: any, property: InvestedProperty) => total + parseFloat(property.value),
    0
  );
  // .toLocaleString();
  //'Modelled year' remaining cash from sales (after CGT)
  const rememainingCashFromSales = properties
    .reduce(
      (total: any, property: InvestedProperty) => total + calculateRemainingCashFromSales(property),
      0
    )
    .toLocaleString();

  ///Loans After Sales (Loans Before Sales + Capital Gain)
  const loansAfterSales = properties
    .reduce((total: any, property: InvestedProperty) => total + calculateLoansAfterSales(property), 0)
    .toLocaleString();
  console.log('loansAfterSales :>> ', loansAfterSales);
  const ModelledPropertyValuesWithNoLoans = properties
    .reduce((total: any, property: InvestedProperty) => {
      const value = Number(calculatePropertyValueWithNoLoans(property, 0));
      return total + (isNaN(value) ? 0 : value);
    }, 0)
    .toLocaleString();


  const outstandingLoansInProjectionYear = properties
    .reduce((total: any, property: InvestedProperty) => total + calculateLoansBeforeSales(property), 0)
    .toLocaleString();

  console.log('outstandingLoansInProjectionYear :>> ', outstandingLoansInProjectionYear);
  // isTestPassed
  const isPassed = isTestPassed() === "Goal Achieved";

  useEffect(() => {
    if (properties.length > 0) {

      properties.map((property: InvestedProperty) => (

        calculateModelledYearPropertyValue(property)
      ))

    }
  }, [calculateModelledYearPropertyValue, properties])


  useEffect(() => {
    // Check if propertyIDS needs to be updated
    if (propertyIDS.length === 0) {
      const initialPropertyIds = properties
        .filter((property: InvestedProperty) => property.sellAndPayOffLoans)
        .map((property: InvestedProperty) => property.id.toString());

      // Only update if the new initialPropertyIds are different from current state
      if (initialPropertyIds.length !== propertyIDS.length) {
        setPropertyIDS(initialPropertyIds);
      }
      return; // Stop further execution to avoid recalculating values on the same render
    }

    // Filter properties based on current propertyIDS
    const filteredProperties = properties.filter((property: InvestedProperty) =>
      propertyIDS.includes(property.id.toString())
    );

    // Calculate new projected values
    const newProjectedValues = filteredProperties.map((property: InvestedProperty) => ({
      id: property.id.toString(),
      value: calculateModelledYearPropertyValue(property)
    }));

    // Update the projected values only if there are changes
    setProjectedValues((prev) => {
      const projectedValueMap = new Map(prev.map((value, index) => [propertyIDS[index], value]));


      newProjectedValues.forEach(({ id, value }: any) => {
        projectedValueMap.set(id, value);
      });

      const updatedProjectedValues = propertyIDS
        .map((id) => projectedValueMap.get(id))
        .filter((value): value is number => value !== undefined);

      // Only update if there's a difference
      if (JSON.stringify(updatedProjectedValues) !== JSON.stringify(prev)) {
        return updatedProjectedValues;
      }
      return prev; // Return previous state if nothing has changed
    });
  }, [calculateModelledYearPropertyValue, properties, propertyIDS]);

  // useEffect(() => {
  //   if (propertyIDS.length === 0) {
  //     // properties.map((property: InvestedProperty) => ({

  //     //  calculateModelledYearPropertyValue(property)
  //     // }));
  //     const initialPropertyIds = properties
  //       .filter((property) => property.sellAndPayOffLoans)
  //       .map((property) => property.id.toString());

  //     setPropertyIDS(initialPropertyIds);
  //     return;
  //   }

  //   const filteredProperties = properties.filter((property) =>
  //     propertyIDS.includes(property.id.toString())
  //   );

  //   const newProjectedValues = filteredProperties.map((property) => ({
  //     id: property.id.toString(),
  //     value: calculateModelledYearPropertyValue(property)
  //   }));

  //   setProjectedValues((prev) => {
  //     const projectedValueMap = new Map(prev.map((value, index) => [propertyIDS[index], value]));

  //     newProjectedValues.forEach(({ id, value }) => {
  //       projectedValueMap.set(id, value);
  //     });

  //     const updatedProjectedValues = propertyIDS.map((id) => projectedValueMap.get(id)).filter((value): value is number => value !== undefined);

  //     return updatedProjectedValues;
  //   });
  // }, [calculateModelledYearPropertyValue, properties, propertyIDS]);


  useEffect(() => {
    const totalProjectedValue = projectedValues.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);

    const loanAmount = Number(outstandingLoansInProjectionYear.replace(/,/g, ''));
    const remainingLoan = loanAmount - Number(totalProjectedValue);

    // Only update if the remaining loan amount is different
    if (remainingLoan !== remainingLoanAmount[0]) {

      setRemainingLoanAmount([remainingLoan < 0 ? 0 : remainingLoan]);
    }
  }, [outstandingLoansInProjectionYear, projectedValues])
  useEffect(() => {
    if (toggleClass) {
      // Perform further logic after remainingLoanAmount updates
    }
  }, [remainingLoanAmount, toggleClass]);
  useEffect(() => {


    const passiveIncome = properties.reduce(
      (total: any, property: InvestedProperty) => total + calculatePreTaxPassiveIncome(property, totalPreTaxPassiveIncome),
      0
    );

    const passiveTotal = Number(passiveIncome) >= Number(passiveGoal?.passive_income);

    const isGoalAchieved = remainingLoanAmount[0] === 0 && passiveTotal;
    setGoalAchieved(isGoalAchieved);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculatePreTaxPassiveIncome, passiveGoal?.passive_income, properties, totalPreTaxPassiveIncome]);

  // useEffect(() => {
  //   const totalProjectedValue = projectedValues.reduce((accumulator, currentValue) => {
  //     return accumulator + currentValue;
  //   }, 0);

  //   const res = Number(outstandingLoansInProjectionYear.replace(/,/g, '')) - Number(totalProjectedValue)
  //   console.log('resammm------------------------->>> ', res);
  //   // 

  //   // eslint-disable-next-line no-lone-blocks
  //   {
  //     Number(totalProjectedValue) < Number(outstandingLoansInProjectionYear.replace(/,/g, '')) ? (
  //       setRemainingLoanAmount([Number(outstandingLoansInProjectionYear.replace(/,/g, '')) - Number(totalProjectedValue)])
  //     ) : (

  //       setRemainingLoanAmount([0])
  //     )
  //   }
  //   console.log('reminingLoanAmount :>> ', remainingLoanAmount);
  //   const passiveIncome = properties.reduce(
  //     (total, property) => total + calculatePreTaxPassiveIncome(property, totalPreTaxPassiveIncome),
  //     0
  //   );

  //   const passiveTotal = Number(passiveIncome) >= Number(passiveGoal?.passive_income);

  //   const isGoalAchieved = remainingLoanAmount[0] === 0 && passiveTotal;
  //   console.log('IsGoalAchieved :>> ', isGoalAchieved);
  //   setGoalAchieved(isGoalAchieved);
  // }, [calculatePreTaxPassiveIncome, outstandingLoansInProjectionYear, passiveGoal?.passive_income, projectedValues, properties, remainingLoanAmount, totalPreTaxPassiveIncome]);
  const handleNext = () => {
    const buySellPortfolioEntry: BuySellPortfolio = {
      modelledYear: properties[0]?.modelledYear,
      isTestPassed: isPassed as unknown as boolean,
      initialPropertyValue: totalPropertyValue as unknown as number,
      modelledPropertyValue: modelledPropertyValues as unknown as number,
      propertyValueNoLoans:
        ModelledPropertyValuesWithNoLoans as unknown as number,
      preTaxPassiveIncome: calculatedValues as unknown as number,
      loansAfterSales: loansAfterSales as unknown as number,
      remainingCash: rememainingCashFromSales as unknown as number,
      outstandingLoansInProjectYear:
        remainingLoanAmount as unknown as number,
      loanPostSales: outstandingLoansInProjectionYear

      // remainingLoanAmount:
    };

    dispatch(updateBuySellPortfolio(buySellPortfolioEntry));
    navigate("/buy-sell-reporting");
  };

  const passiveIncomeArray: BarChartPreTaxPassiveIncome[] = [];

  properties.forEach((property: InvestedProperty, index: number) => {
    const passiveIncome = calculatePreTaxPassiveIncome(
      property,
      totalPreTaxPassiveIncome
    );
    const incomeColor = "hsl(76, 70%, 50%)";
    passiveIncomeArray.push({
      property: property.address.split(" ").slice(0, 2).join(" "),
      "pre-tax income": passiveIncome.toLocaleString().replace(/,/g, ""),
      "pre-tax incomecolor": incomeColor,
    });
  });

  dispatch(setBarChartPreTaxPassiveIncome(passiveIncomeArray));

  /// pie chart loans after sales

  const loansAfterSalesObject: Record<string, string> = {};
  properties.forEach((property: InvestedProperty, index: number) => {
    const loansAfterSalesValue =
      calculateLoansAfterSales(property).toLocaleString();
    loansAfterSalesObject[property.address.split(" ").slice(0, 2).join(" ")] =
      loansAfterSalesValue;
  });
  // console.log("loansAfterSalesObject:", loansAfterSalesObject);
  const loansAfterSalesArray = Object.keys(loansAfterSalesObject).map(
    (key, index) => {
      const valueWithoutSeparator = loansAfterSalesObject[key].replace(
        /,/g,
        ""
      ); // Remove thousand separator
      const value = Number(valueWithoutSeparator); // Convert to number
      const labelWithValue = `${key} ($ ${value})`; // Concatenate value to label
      return {
        id: labelWithValue,
        label: labelWithValue,
        value: value,
        color: `hsl(${104 + index * 58}, 70%, 50%)`, // Generate color based on index
      };
    }
  );

  dispatch(setPieChartLoansAfterSalesItem(loansAfterSalesArray));


  const propertiesArray = properties.map(
    (property: { value: any }) => property.value
  );
  const propertiesObject: { [key: string]: any } = {};
  propertiesArray.forEach((value: any, index: number) => {
    propertiesObject[`prop value ${index + 1}`] = value;
  });


  ///signal loan before sales// Calculate loan values before sales for each property
  const loanValuesBeforeSales = properties.map((property: InvestedProperty, index: number) => ({
    [`loan before sales ${index + 1}`]: calculateLoansBeforeSales(property),
  }));

  const loanValuesBeforeSalesObject = Object.assign(
    {},
    ...loanValuesBeforeSales
  );

  /// single loan after sales

  const loanaftersales = properties.map((property: InvestedProperty, index: number) => ({
    [`loan after sales ${index + 1}`]: calculateLoansAfterSales(property),
  }));
  const loanValuesAfterSalesObject = Object.assign({}, ...loanaftersales);

  /// signal remaining cash from sales after (CGT)

  const remainingCashFromSalesAfterCGT = properties.map((property: InvestedProperty, index: number) => ({
    [`remaining cash after (CGT) ${index + 1}`]:
      calculateRemainingCashFromSales(property),
  }));
  const remainingCashFromSalesAfterCGTObject = Object.assign(
    {},
    ...remainingCashFromSalesAfterCGT
  );

  /// signal property value with no loans
  const propertiesWithNoLoans = properties.map((property: InvestedProperty, index: number) => ({
    [`property value with no loans ${index + 1}`]:
      calculatePropertyValueWithNoLoans(property, totalPreTaxPassiveIncome),
  }));
  const propertiesWithNoLoansObject = Object.assign(
    {},
    ...propertiesWithNoLoans
  );

  /// single pre tax passive income

  const preTaxPassiveIncome = properties.map(
    (property: InvestedProperty, index: number) => ({
      [`pre tax passive income ${index + 1}`]: calculatePreTaxPassiveIncome(
        property,
        totalPreTaxPassiveIncome
      ),
    })
  );
  const preTaxPassiveIncomeObject = Object.assign({}, ...preTaxPassiveIncome);

  const modelledChartData: ModelledChartData = {
    loanValuesBeforeSalesObject,
    loanValuesAfterSalesObject,
    remainingCashFromSalesAfterCGTObject,
    propertiesWithNoLoansObject,
    preTaxPassiveIncomeObject,
  };

  /// line chart modelled year property value
  const transformData = (): ChartData[] => {
    const transformedData: ChartData[] = [];
    const categories = Object.keys(modelledChartData);

    // Define a mapping of category IDs to colors
    const colorMapping: { [key: string]: string } = {
      loanValuesBeforeSalesObject: "darkgreen",
      loanValuesAfterSalesObject: "darkblue",
      remainingCashFromSalesAfterCGTObject: "darkgreen",
      propertiesWithNoLoansObject: "darkorange",
      preTaxPassiveIncomeObject: "purple",
    };

    categories.forEach((category) => {
      const color = colorMapping[category] || "black"; // Default to black if no color is specified for the category
      const data = Object.keys(modelledChartData[category]).map(
        (key, index) => ({
          x: properties[index].address.split(" ").slice(0, 2).join(" "), // Extract the property number from the key
          // x: `property_${parseInt(key.replace(/[^\d]/g, ""))}`, // Extract the property number from the key
          y: modelledChartData[category][key],
        })
      );

      transformedData.push({
        id: category
          .replace(/Object/g, "")
          .replace(/([A-Z])/g, " $1")
          .trim()
          .toLowerCase(),
        color: color,
        data: data,
      });
    });

    return transformedData;
  };

  const transformedData = transformData();

  dispatch(setLineChartModelledPropertyValue(transformedData));

  const testResult = isTestPassed(); // Get the test result
  // console.log(testResult, "222222222222222222")
  // Determine the CSS class based on the test result
  const textClass =
    testResult === "Goal Achieved"
      ? "gradient-text mr-1"
      : "notachivegradient-text";

  const chartData = propertyGraph.map((prop) => ({
    name: prop.address,
    "Initial Value": prop.initialValue,
    //@ts-ignore
    "Projected Value": Math.round(prop.projectedValue),
    "Passive Income": Math.round(prop.passiveIncome),
  }));




  const computeProjectedValue = (property: any) => {

    return calculateModelledYearPropertyValue(property);
  };

  const computePassiveIncome = (property: any) => {

    return calculatePreTaxPassiveIncome(property, totalPreTaxPassiveIncome)
  };


  const barData = properties.map((property: InvestedProperty) => ({
    name: property.address?.split(" ").slice(0, 3).join(" ") || 'Unknown',
    'Initial Value': Number(property.value),
    'Projected Value': computeProjectedValue(property),
    'Passive Income': computePassiveIncome(property),
    'Is PPOR': property.ispporproperty ? 'True' : 'False'
  }));
  console.log(properties, '------------1212121212------------------------->>>>>>')

  // useEffect(() => {
  //   setRemainingLoanAmount([Number(outstandingLoansInProjectionYear)])
  // }, [outstandingLoansInProjectionYear])

  // const passiveIncome = properties.reduce((total: any, property: any) => total + calculatePreTaxPassiveIncome(property, totalPreTaxPassiveIncome))
  // const goalAchieved = Number() > Number(passiveGoal) && Number(passiveGoal);


  // const buttons = (
  //   <React.Fragment>
  //     <Box sx={{ display: 'flex', justifyContent: 'center' }}>
  //       <Button onClick={handleClick({ vertical: 'top', horizontal: 'center' })}>
  //         Top-Center
  //       </Button>

  //   </React.Fragment>
  // );
  return (
    <>
      <div>
        <Grid container spacing={3} columns={12} className="p-6">

          {/* {remainingLoanAmount[0] === 0 && ( */}

          {/* )} */}
          {/* {remainingLoanAmount[0] === 0 &&
            <Box sx={{ width: 500, vertical: 'top', horizontal: 'center' }}>
              <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={remainingLoanAmount[0] === 0 && open}
                // onClose={handleClose}
                message="I love snacks"
                key={vertical + horizontal}
              />
            </Box>
          } */}

          <Grid item md={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "16px",
              }}
            >

              {/* {GoalAchieved && (
              <Box sx={{ backgroundColor: "#4cceac" }}>
                <Snackbar
                  anchorOrigin={{ vertical: snackBarState.vertical, horizontal: snackBarState.horizontal }}
                  open={snackBarState.open}
                  autoHideDuration={2000} 
                  onClose={handleSnackBarClose}
                  // onExited={handleSnackBarClose}
                  message="No outstanding loans 🏆" 
                  key={`${snackBarState.vertical}${snackBarState.horizontal}`}
                />
              </Box>
            )} */}


              {/* <IconButton onClick={handleBack} style={{ marginRight: "8px" }}>
                <ArrowBackIcon />
              </IconButton> */}

              <Button onClick={handleBack} color="secondary">
                <ArrowBackIcon sx={{ marginRight: "5px" }} /> Go Back
              </Button>
              <Typography variant="h4" gutterBottom>
                Invest Net : Reporting
              </Typography>
              <Typography
                variant="h4"
                align="right"
                className={textClass}
                style={{
                  marginLeft: "auto",
                  fontWeight: "bold",
                }}
              >
                {testResult}
              </Typography>
            </div>
          </Grid>
          <Grid item md={12} lg={5}>
            <Typography
              variant="subtitle1"
              gutterBottom
              style={{
                marginRight: "8px",
                color: colors.grey[100],
                fontWeight: "bold",
                marginLeft: 2,
              }}
            >
              {/* Modelled Year - {properties[0]?.modelledYear === 0 ? modelledYear : properties[0]?.modelledYear} */}
              Modelled Year - {modelledYear}
            </Typography>

            <Slider
              // value={properties[0]?.modelledYear === 0 ? modelledYear : properties[0]?.modelledYear}
              value={modelledYear}
              min={2024}
              max={2040}
              step={1}
              valueLabelDisplay="auto"
              onChange={handleModelledYearChange}
              aria-labelledby="vacancy-period-slider"

              marks

              sx={{ color: colors.greenAccent[500], width: '50%' }}
            />
          </Grid>
          <Grid item md={12} lg={7} style={{ display: "flex", gap: "1rem" }}>
            <TextField
              label="Passive Income Goal"
              type="text"
              InputProps={{
                startAdornment: '$',
              }}

              value={(passiveGoal && Number(passiveGoal.passive_income)?.toLocaleString()) || ''}

            />
            <>

              {/* <TextField
                label="Effective tax (if sold)"
                type="text"
                value={properties[0]?.marginalTaxRate || ""}
                InputProps={{
                  endAdornment: '%',
                }}
                // onChange={(event) =>
                //   handleMarginalTaxRateChange(event)
                // }
                onChange={handleMarginalTaxRateChange}
              /> */}

            </>
            {/* {testResult === "Goal Achieved" && */}
            <TextField
              label="Total Projected Value"
              type="text"

              InputProps={{
                startAdornment: '$',
              }}
              // value={testResult === "Goal Achieved" ? modelledPropertyValues : 0}
              value={testResult === "Goal Achieved" ? ModelledPropertyValuesWithNoLoans : 0}
            />

            <TextField
              label="Outstanding Loan Amount"
              type="text"
              className="gradient-border"



              InputProps={{
                startAdornment: '$',
              }}
              // value={testResult === "Goal Achieved" ? modelledPropertyValues : 0}
              value={remainingLoanAmount[0]?.toLocaleString() ?? 0}
            />
            {/* <TextField
              label="CGT Rate (%)"
              type="number"
              value={33}
              InputProps={{
                endAdornment: '%',
              }}
            // onChange={(e) => handleAssumptionChange('cgtRate', e.target.value)}
            // fullWidth
            // margin="normal"
            /> */}
            {/* } */}
            <TextField
              label="PPOR Loan"
              type="number"
              value={sumValuesForPporProperties(properties, true).toFixed(0)}
              // onChange={(e) => handleAssumptionChange('pporLoan', e.target.value)}
              InputProps={{
                startAdornment: '$',
              }}
            />

          </Grid>



          <Container>
            <Typography
              variant="h4"
              component="h1"
              gutterBottom
              textAlign="center"
              marginTop={4}
              sx={{ color: colors.grey[100], py: 2 }}
            >
              Investnet Scenario
            </Typography>

            {/* Scenario Management UI */}
            <Box sx={{ display: "flex", justifyContent: "space-between", mb: 4, color: colors.grey[100] }}>
              <Select
                value={selectedScenarioId || ""}
                onChange={(e) => setSelectedScenarioId(e.target.value as string)}
                displayEmpty
                sx={{ minWidth: 200 }}
              >
                <MenuItem value="" disabled>Select a scenario</MenuItem>
                {scenarios?.map((scenario: any) => {
                  return (
                    <MenuItem key={scenario.id} value={scenario.id}>{scenario.scenario_name}</MenuItem>

                  )
                })}
              </Select>
              <Box>
                {/* <Button
                  variant="contained"
                  color="primary"
                  onClick={handleLoadScenario}
                  startIcon={<FolderOpenIcon />}
                  sx={{ mr: 2 }}
                  disabled={!selectedScenarioId}
                >
                  Load Scenario
                </Button> */}
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleDeleteScenario}
                  startIcon={<DeleteIcon />}
                  disabled={!selectedScenarioId}
                >
                  Delete Scenario
                </Button>
              </Box>
            </Box>



            <Grid item md={12}>
              <Card
                sx={{
                  padding: "20px !important",
                  marginBottom: "0px !important",
                  borderRadius: "15px !important",
                  boxShadow: "none !important",
                  backgroundColor: colors.primary[400],
                }}
              >

                <Grid container spacing={2} sx={{ marginTop: '20px' }}>
                  <Grid item xs={12} md={12}>
                    <TableContainer
                      className="table_styleTwo"
                      component={Paper}
                      sx={{ marginBottom: '20px' }}
                    >
                      <Table sx={{ minWidth: 700 }} aria-label="results table">
                        <TableHead >
                          <TableRow>
                            <TableCell sx={{ fontWeight: 'bold' }}>Property</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }} align="right" >Initial Value</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }} align="right">Projected Value</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }} align="right">Loan</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }} align="right">Growth Rate</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }} align="right">Net Yield</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }} align="right">Effective Tax Rate</TableCell>

                            <TableCell sx={{ fontWeight: 'bold' }} align="right">Passive Income</TableCell>
                            {/* <TableCell sx={{ fontWeight: 'bold' }} align="right">Is PPOR</TableCell> */}
                            {/* <TableCell sx={{ fontWeight: 'bold' }} align="right">Sell Decision</TableCell> */}
                            <TableCell sx={{ fontWeight: 'bold' }} align="right">Sell Year</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {properties && properties.map((property: any, propertyIndex: number) => {
                            const sellYear = currentScenario?.properties.find((p: any) => p.id === property.id)
                            // console.log(sellYear, property, 'oooooooooooooooooooooooooooooooooooooooooooooo');

                            return (
                              <TableRow
                                key={propertyIndex}
                                className={property.sellAndPayOffLoans ? 'chnagebackgound' : ''}
                              >
                                <TableCell component="th" scope="row">
                                  {property.ispporproperty && (
                                    <Typography
                                      sx={{ padding: "1px", backgroundColor: "#4cceac", borderRadius: "3px", fontSize: "12px", width: "40px", textAlign: "center", alignItems: 'flex-end' }}
                                    >
                                      PPOR
                                    </Typography>
                                  )}
                                  {property.address?.split(" ").slice(0, 3).join(" ")}
                                </TableCell>
                                <TableCell align="right">
                                  $ {property.value.toLocaleString()}
                                </TableCell>
                                <TableCell align="right">
                                  $ {calculateModelledYearPropertyValue(property).toLocaleString()}
                                </TableCell>
                                <TableCell align="right">
                                  $ {calculateLoansBeforeSales(property).toLocaleString()}
                                </TableCell>
                                <TableCell align="right">
                                  <TextField
                                    type="number"
                                    value={property.compoundingAnnualGrowthRate || ""}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleCompoundingAnnualGrowthRateChange(event, property.id)}
                                    inputProps={{ min: 0, max: 100, step: 0.01 }}
                                    sx={{ width: '60px' }}
                                  /> %
                                </TableCell>
                                <TableCell align="right">
                                  <TextField
                                    type="number"
                                    value={property.netYield}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleNetYieldChange(event, property.id)}
                                    inputProps={{ min: 0, max: 100, step: 0.01 }}
                                    sx={{ width: '60px' }}
                                  /> %
                                </TableCell>
                                <TableCell align="right">
                                  <TextField
                                    type="number"
                                    value={ property.ispporproperty?0:property.effectiveTaxRate || 33}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleEffectiveTaxRateChange(e, property.id)}
                                    inputProps={{ min: 0, max: 100, step: 0.1 }}
                                    sx={{ width: '60px' }}
                                  />%
                                </TableCell>
                                <TableCell align="right">
                                  $ {calculatePreTaxPassiveIncome(property, totalPreTaxPassiveIncome).toLocaleString()}
                                </TableCell>
                                <TableCell align="right">
                                  <FormControl>
                                    <Select
                                      value={currentScenario?.properties.find((p: any) => p.id === property.id)?.sellYear?.toString() || "Don't Sell"}
                                      defaultValue="Don't Sell"
                                      onChange={(e) => {
                                        const propertyIndex = properties.findIndex((p: Property) => p.id === property.id);
                                        const newSellYear = e.target.value;

                                        handleModelledSellYearChange(property.id, 'sellYear', newSellYear, propertyIndex);
                                        handleSellAndPayOffLoansChange(newSellYear, property.id);
                                      }}
                                    >
                                      <MenuItem value="Don't Sell">Don't Sell</MenuItem>
                                      {Array.from({ length: 2040 - 2024 + 1 }, (_, i) => 2024 + i).map((year) => (
                                        <MenuItem key={year} value={year.toString()}>{year}</MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </TableCell>
                              </TableRow>
                            );
                          })}

                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>

                  {/* <Grid item xs={12} md={4}>
                  <Paper sx={{ padding: '20px' }}>
                    <Typography variant="h6" gutterBottom>Assumptions</Typography>
                    <TextField
                      label="CGT Rate (%)"
                      type="number"
                      value={33}
                      // onChange={(e) => handleAssumptionChange('cgtRate', e.target.value)}
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      label="Passive Income Goal"
                      type="number"
                      value={passiveIncome}
                      // onChange={(e) => handleAssumptionChange('goalPassiveIncome', e.target.value)}
                      fullWidth
                      margin="normal"
                    />
                    <TextField
                      label="PPOR Loan"
                      type="number"
                      value={sumValuesForPporProperties(properties, true).toFixed(0)}
                      // onChange={(e) => handleAssumptionChange('pporLoan', e.target.value)}
                      fullWidth
                      margin="normal"
                    />
                    <GoalProgressSection />
                  </Paper>
                </Grid> */}
                </Grid>
              </Card>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: 4,
                color: colors.grey[100],
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSave}
                startIcon={<BookmarkAddedOutlinedIcon />}
              >
                Save Scenario
              </Button>
              {/* <Button
                variant="contained"
                color="secondary"
                onClick={handleNext}
                endIcon={<ArrowForwardIcon />}
              >
                Next
              </Button> */}
            </Box>

            <Dialog open={saveDialogOpen} onClose={() => setSaveDialogOpen(false)}>
              <DialogTitle>Save Scenario</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="scenarioName"
                  label="Scenario Name"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={scenarioName}
                  onChange={(e) => setScenarioName(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setSaveDialogOpen(false)}>Cancel</Button>
                <Button onClick={handleSaveScenario}>Save</Button>
              </DialogActions>
            </Dialog>
          </Container>










          <Grid container spacing={2} sx={{ marginTop: '20px' }}>
            <Grid item xs={12} md={8}>
              {/* <Grid item md={12}> */}
              <Card
                sx={{
                  padding: "20px !important",
                  marginBottom: "0px !important",
                  borderRadius: "15px !important",
                  boxShadow: "none !important",
                  backgroundColor: colors.primary[400],
                }}
              >
                {/* <Typography variant="h5" sx={{ marginTop: '20px', color: goalAchieved ? 'green' : 'red', fontWeight: 'bold' }}>  */}
                <Typography
                  variant="h4"
                  align="left"
                  className={textClass}
                  style={{
                    marginLeft: "auto",
                    fontWeight: "bold",
                  }}
                >
                  {properties.reduce(
                    (total: any, property: InvestedProperty) => total + calculatePreTaxPassiveIncome(property, totalPreTaxPassiveIncome),
                    0
                  ).toLocaleString() !== "0" ? (
                    <>
                      Total Passive Income: ${properties.reduce(
                        (total: any, property: InvestedProperty) => total + calculatePreTaxPassiveIncome(property, totalPreTaxPassiveIncome),
                        0
                      ).toLocaleString()} - {testResult}
                    </>
                  ) : (
                    <>
                      {testResult}
                    </>
                  )}

                </Typography>
                {/* <Typography
                variant="h4"
                align="left"
                className={textClass}
                style={{
                  marginLeft: "auto",
                  fontWeight: "bold",
                }}
              >
                {testResult}
              </Typography> */}

                <Box sx={{ height: "400px", marginTop: "20px" }}>

                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={barData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <ChartTooltip />
                      <Legend />
                      <Bar dataKey="Initial Value" fill="#8884d8" barSize={40} />
                      <Bar dataKey="Projected Value" fill="#82ca9d" barSize={40} />
                      <Bar dataKey="Passive Income" fill="#ffa726" barSize={40} />{" "}


                    </BarChart>
                  </ResponsiveContainer>

                </Box>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  padding: "20px !important",
                  marginBottom: "0px !important",
                  borderRadius: "15px !important",
                  boxShadow: "none !important",
                  backgroundColor: colors.primary[400],
                }}
              >

                <GoalProgressSection />
              </Card>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: "96px",
                }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleNext}
                >
                  Next
                  <ArrowForwardIcon sx={{ ml: 2 }} />
                </Button>
              </div>
            </Grid>
          </Grid>
          {/* <Grid item md={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "36px",
              }}
            >
             
              <Button
                variant="contained"
                color="secondary"
                onClick={handleNext}
              >
                Next
                <ArrowForwardIcon sx={{ ml: 2 }} />
              </Button>
            </div>
          </Grid> */}
        </Grid>
      </div >
    </>
  );
};
export default PropertyResultsPage;