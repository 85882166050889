import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BarChartPreTaxPassiveIncome, LineChartModelledPropertyValue, PieChartLoansAfterSalesItem, BuySellPortfolio, InvestedProperty } from '../types/BuySell';

interface InvestedPropertiesState {
  properties: InvestedProperty[];
  buySellPortfolio: BuySellPortfolio[];
  barChartPreTaxPassiveIncome: BarChartPreTaxPassiveIncome[];
  pieChartLoansAfterSalesItem: PieChartLoansAfterSalesItem[]
  lineChartModelledPropertyValue: LineChartModelledPropertyValue[]
}

const initialState: InvestedPropertiesState = {
  properties: [],
  buySellPortfolio: [],
  barChartPreTaxPassiveIncome: [],
  pieChartLoansAfterSalesItem: [],
  lineChartModelledPropertyValue: [],
};

let nextPropertyId = 1;

const investedPropertiesSlice = createSlice({
  name: 'investedProperties',
  initialState,
  reducers: {
    updateProperty: (state, action: PayloadAction<{ index: number; data: Partial<InvestedProperty> }>) => {
      const { index, data } = action.payload;
      state.properties[index] = { ...state.properties[index], ...data };
    },
    updateBuySellPortfolio: (state, action: PayloadAction<BuySellPortfolio>) => {

      state.buySellPortfolio.push(action.payload);

    },
    setBarChartPreTaxPassiveIncome: (state, action: PayloadAction<BarChartPreTaxPassiveIncome[]>) => {
      state.barChartPreTaxPassiveIncome = action.payload;
    },
    setPieChartLoansAfterSalesItem: (state, action: PayloadAction<PieChartLoansAfterSalesItem[]>) => {
      state.pieChartLoansAfterSalesItem = action.payload;
    },
    setLineChartModelledPropertyValue: (state, action: PayloadAction<LineChartModelledPropertyValue[]>) => {
      state.lineChartModelledPropertyValue = action.payload;
    },
    addProperty: (state) => {
      state.properties.push({
        id:  nextPropertyId++,
        address: '',
        value: '',
        purchaseYear: 0,
        deposit: 0,
        leverageRatio: 0,
        compoundingAnnualGrowthRate: 7,
        sellAndPayOffLoans: false,
        loanBeforeSales: 0,
        marginalTaxRate: 45,
        netYield: 4,
        equityAccessed: 0,
        modelledYear: 0,
        ispporproperty: false,
        sellYear: false,
        effectiveTaxRate: 0

      });
    },
    removeProperty: (state, action: PayloadAction<number>) => {
      const index = state.properties.findIndex((property) => property.id === action.payload);
      if (index !== -1) {
        state.properties.splice(index, 1);
      }
    },

  },
});

export const { updateProperty, addProperty, setBarChartPreTaxPassiveIncome, setPieChartLoansAfterSalesItem, setLineChartModelledPropertyValue, removeProperty, updateBuySellPortfolio } = investedPropertiesSlice.actions;

export default investedPropertiesSlice.reducer;