import { InvestedProperty } from "./BuySell";
import { LoanFunding } from "./finance";

export interface FamilyMember {
  id:number;
  name: string;
  // relation: string;
}

export interface DependentKid {
  id:number;
  name: string;
  age: string;
}

export enum PropertyType {
  OwnerOccupied = "OwnerOccupied",
  Investment = "Investment",
}

export interface Property {
  address: string;
  type: PropertyType;
  investmentPropertyDetails?:InvestedProperty[];
}

export enum EmploymentType {
  PAYG = "PAYG",
  SelfEmployed = "SelfEmployed",
}

export interface PersonalInfo {
  id: string;
  first_name: string;
  last_name: string;
  email?: string;
  phone_number?: string;
}

export interface FinancialGoal {
  year_of_retirement: number;
  number_of_properties: number;
  equity: number;
  passive_income: number;
  targetRentalYield: number;
  isFirstIp: boolean;
  // rentVesting: boolean;
  needAssetProtection: boolean;
}

export interface FamilyInfo {
  earningMembers: FamilyMember[];
  dependentKids: DependentKid[];
}

export interface CurrentPortFolio {
  propertydetails: any;
  id:null,
 address:string,
 propertyid:string,
  // title:string,
  yearofpurchase:number,
  currentestimatedvalue:string,
  initialpurchaseprice:number,
  netrentalyield:number,
  weeklyrent:number,
  currentloanamount:number,
  ispporproperty:any
}
export interface CurrentPortfolioInfo {

  investmentProperties:CurrentPortFolio[]
  // length: number;
  // bitcoinValue: number;
  // cash: number;
  // otherInvestments: Record<number, unknown>;
  // address:string,
  // title:string,
  // currentEstimatedValue:number,
  // initialDeposit:number,
  // netRentalYield:number,
  // currentLoanAmount:number
}

// export interface Income {
//   income: Record<number, unknown>;
// }

export interface Expenses {
  existingInvestmentLoanAmount: number;
  personalLoansAmount: number;
  creditCardLimit: number;
  livingExpenses: number;
  leasePayments: number;
  miscExpenses: number;
}
export interface EmploymentInfo {
  employmentType: EmploymentType;
  // bonus?: number;
  selfEmployedIncome: number;
  // rentalIncome: number;
  netMonthlyIncomeMA: number;
  netMonthlyIncomeFA: number;
  dividends?: number;
  overtime?: number;
  otherIncome?: number;
  centreLink?: number;
  portfolio_value?: number;
  totalInvestedPropertyValue?: number;// TODO: Should be replaced with InvestedProperty.value
}

export interface PropertyInfo {
  properties: Property[];
}


export interface LoanInfo {
  loanFinance?: LoanFunding;
}
// export interface InvestmentSituation{
//   targetRentalYield: number;
//   isFirstIp: boolean;
//   rentVesting: boolean;
//   needAssetProtection: boolean;
// }

export interface InvestmentStrategy {
  strategy: Record<number, unknown>;
}
 export interface Consent {
  status?: any;
  // Add other properties related to consent
}
export interface BankingInfo {
  consent: Consent; // Array for consent
  consentUrl: String; // Array for consentUrl
  accounts: any[]; // Array for typeAny
}


export interface UserState {
  personalInfo: PersonalInfo;
  financialGoal: FinancialGoal;
  familyInfo: FamilyInfo;
  currentPortfolio: CurrentPortfolioInfo;
  // income: Income;
  expenses: Expenses;
  employmentInfo: EmploymentInfo;
  propertyInfo: PropertyInfo;
  loanInfo: LoanInfo;
  investmentStrategy: InvestmentStrategy;
  bankingInfo:BankingInfo;
}