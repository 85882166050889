
export const calculateBorrowableEquity = (investmentProperties: any) => {

  // return investmentProperties?.map((property: any) => {
  const loan = investmentProperties?.value - investmentProperties?.deposit
  const currentEstimatedValue =
    parseFloat(investmentProperties?.value) || 0;
  const currentLoanAmount = loan || 0;
  return currentEstimatedValue * 0.8 - currentLoanAmount;
  // });
};
// export const calculateBorrowableEquity = (investmentProperties: any) => {
//   // Check if investmentProperties is an array
//   if (!Array.isArray(investmentProperties)) {
//     console.warn('Expected investmentProperties to be an array, but got:', investmentProperties);
//     return []; // Return an empty array if it's not an array
//   }

//   return investmentProperties.map((property: any) => {
//     const currentEstimatedValue = parseFloat(property.currentestimatedvalue) || 0;
//     const currentLoanAmount = parseFloat(property.currentloanamount) || 0;
//     return currentEstimatedValue * 0.8 - currentLoanAmount;
//   });
// };
